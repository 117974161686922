import React from "react";
import "./css/error.css";

function ServerBad() {
  return (
    <div className="error">
      <h1 className="title">500</h1>
      <h3 className="descript">服务已崩溃！</h3>
    </div>
  );
}

export default ServerBad;
