import React, { Component } from "react";

class AlipayPC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: "",
    };
    this.handlePay = this.handlePay.bind(this);
  }

  handlePay = () => {
    console.log("你点我进行支付啦！！！");

    fetch("/api/pay/pay/alipaypc", { method: "get" })
      .then(response => {
        return response.text();
      })
      .then(data => {
        console.log(data);
        this.setState({ payload: data });
        setTimeout(() => {
            document.alipaysubmit.submit();
        }, 1000);
      });
  };

  render() {
    const { payload } = this.state;

    return (
      <div>
        <button
          onClick={this.handlePay}
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
        >
          点我支付
        </button>
        <div dangerouslySetInnerHTML={{ __html: payload }}></div>
      </div>
    );
  }
}

export default AlipayPC;
