import React, { Component, Fragment } from "react";
import html2canvas from "html2canvas";
import request from "../utils/request";
import "../../css/article.css";
import MenImg from "../../image/share/men.png";
import WomanImg from "../../image/share/woman.png";
import DownloadImg from "../../image/share/download.png";
import CloseImg from "../../image/share/close.png";
import { Chart, Axis, Geom, Tooltip } from "bizgoblin";

const pixelRatio = window.devicePixelRatio * 2;

function exportImage() {
  var views = document.querySelector("#views");
  html2canvas(views, {
    scale: window.devicePixelRatio,
    useCORS: true,
  }).then(function (canvas) {
    let imgUrl = canvas.toDataURL("image/png");
    let today = new Date();
    let date =
      today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate();
    downloadIamge(imgUrl, "个人学习汇报-" + date + ".png");
    //dataImg.src = canvas.toDataURL('image/png')
  });
}

function downloadIamge(imgsrc, name) {
  //下载图片地址和图片名
  let image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let context = canvas.getContext("2d");
    context.drawImage(image, -15, 0, image.width, image.height);
    let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    let a = document.createElement("a"); // 生成一个a元素
    let event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}

const defs = [
  {
    dataKey: "type",
  },
  {
    dataKey: "value",
    formatter(value) {
      return `${value}%`;
    },
  },
];
const httphost =  'http://' + window.location.host;
    const array = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
const status = ["未完成", "进展中", "达标", "熟练", "特别熟练"];

class PushArticle extends Component {
  constructor(props){
    super(props);

    this.state = {
      payload: undefined,
      voiceWCPM: undefined
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  componentDidMount() {
    document.title = "文章训练日报";
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      request(`/api/user/push/article?data=${match.params.id}`).then((data) => {
        if (data.code === 0) {
          this.setState({ payload: data.payload });
        }
      });
    }
  }

  handleClick = (data)=>{
    this.setState({voiceWCPM: data});
    document.getElementById('box').style.display = "block";
  }

  handleClose = ()=>{
    document.getElementById('box').style.display = "none";
  }

  render() {

    const { payload, voiceWCPM } = this.state;
    
    return (
      <div className="share">
        <img
          onClick={exportImage}
          className="download"
          src={DownloadImg}
          alt="我的下载"
        />
        <div id="views" className="share_all">
          <div className="share_topp">
            <div className="share_top_div left">
              <img
                src={
                  payload &&
                  (payload.sex === 2
                    ? WomanImg
                    : payload.sex === 1
                    ? MenImg
                    : "")
                }
                alt="我的头像"
              />
            </div>
            <div className="share_top_div left">
              <p className="fontSize_max fontSize_weight marginTop fontColorm">
                {payload && payload.studentName}&nbsp;
                {payload && payload.loginName}
              </p>
              <p className="fontSize_small fontColory">姓名 Name</p>
              <p className="fontSize_max fontColorm">
                <span className="fontNumber">
                  {payload && payload.listName}
                </span>
              </p>
              <p className="fontSize_small fontColory">级别 Level</p>
              <p className="fontSize_max fontNumber fontColorm">
                {payload && payload.dateNow}
              </p>
              <p className="fontSize_small fontColory">日期 Date</p>
              <p className="fontSize_max fontNumber fontColorm">
                {payload && payload.finish}
              </p>
              <p className="fontSize_small fontColory">完成文章 Selections</p>
            </div>
          </div>
          <div className="share_content">
            {payload && payload.articles && payload.articles.map((art)=><Fragment key={art.id}>
            <div className="art_1">
              <div className="art_title">{art.articleName}</div>
              <div className="art_1_content">
                <p className="small_title">
                  <span>文章详情</span>Selection Details
                </p>
                <div className="art_left">
                  <div className="art_row">
                    <div className="art_row_left">
                      <span className="art_row_zh">文章学习情况:</span>
                      <span className="art_row_en hui">Selection Performance</span>
                    </div>
                    <div className="art_row_right non-weight">
                      {art.status == 0 ? "未完成" : art.status > 0 ? <img src={`${httphost}/library/star_${art.status}.png`} /> : ""}
                    </div>
                  </div>
                  <div className="art_row">
                    <div className="art_row_left">
                      <span className="art_row_zh">阅读年级:</span>
                      <span className="art_row_en hui">ATOS GE</span>
                    </div>
                    <div className="art_row_right fontNumber">
                      {art.grade == 0 ? "K" : art.grade}
                    </div>
                  </div>
                  <div className="art_row">
                    <div className="art_row_left">
                      <span className="art_row_zh">阅读等级:</span>
                      <span className="art_row_en hui">Guided Reading</span>
                    </div>
                    <div className="art_row_right fontNumber">
                      {array[art.level]}
                    </div>
                  </div>
                  <div className="art_row">
                    <div className="art_row_left">
                      <span className="art_row_zh">WCPM目标:</span>
                      <span className="art_row_en hui">Target WCPM</span>
                    </div>
                    <div className="art_row_right fontNumber">
                      {art.wcpmTarget}
                    </div>
                  </div>
                  <div className="art_row">
                    <div className="art_row_left">
                      <span className="art_row_zh">蓝思分级:</span>
                      <span className="art_row_en hui">Lexile</span>
                    </div>
                    <div className="art_row_right fontNumber">
                      {art.lexile}
                    </div>
                  </div>
                </div>
                <div className="art_right">
                  <img src={`${httphost}/library/book_${art.id}.png`} />
                </div>
              </div>
            </div>
            <div className="art_2">
              <div className="art_title">
                <span className="zh">听读详情</span>
                <span className="en">Read To Me Details</span>
              </div>
              <div className="art_2_content">
                <table className="table-1">
                  <thead>
                    <tr>
                      <td>
                        <span className="tab_title">今日听读次数</span>
                        <span className="tab_title hui">Today’s RTM</span>
                      </td>
                      <td>
                        <span className="tab_title">今日时长</span>
                        <span className="tab_title hui">Today’s Time</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="orange fontNumber">{art.listenCount}</td>
                      <td className="orange fontNumber">{art.times}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="art_3">
              <div className="art_title">
                <span className="zh">录音详情</span>
                <span className="en">Recording Details</span>
              </div>
              <div className="art_3_content">
                <table className="table-1">
                  <thead>
                    <tr>
                    <td>
                        <span className="tab_title">今日录音次数</span>
                        <span className="tab_title hui">Today’s recording</span>
                      </td>
                      <td>
                        <span className="tab_title">今日最高WCPM</span>
                        <span className="tab_title hui">Highest WCPM</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="orange fontNumber"><span className="line" onClick={()=>this.handleClick(art.voiceWCPM)}>{art.voiceCount}</span></td>
                      <td className="orange fontNumber">{art.wcpmMaxDay}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="art_4">
              <div className="art_title">
                <span className="zh">理解详情</span>
                <span className="en">Comprehension Details</span>
              </div>
              <div className="art_4_content">
                <table className="table-1">
                  <thead>
                    <tr>
                    <td>
                        <span className="tab_title">导读思考 <span className="hui">TAI</span></span>
                      </td>
                      <td>
                        <span className="tab_title">阅读理解 <span className="hui">Quiz</span></span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="coffee fontNumber">
                        {art.previewDay}
                      </td>
                      <td className="coffee fontNumber">
                        {art.readDay}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="art_5">
              <div className="art_title">
                <span className="zh">今日学习情况</span>
                <span className="en">Today’s Performance</span>
              </div>
              <div className="art_5_content">
                <Chart
                  width="100%"
                  height="400"
                  data={art.chart}
                  defs={defs}
                  animate={{ type: "scaley" }}
                  padding={[60, 30, 60, 80]}
                >
                  <Axis dataKey="type" label={{ fontSize: 17 }} />
                  <Axis dataKey="value" label={{ fontSize: 17 }} />
                  <Tooltip />
                  <Geom
                    geom="interval"
                    position="type*value"
                    color={[
                      "type*value",
                      (type, value) => {
                        if (type === "导读思考 TAI") {
                          return "#ff8f05";
                        } else if (type === "WCPM") {
                          return "#afd100";
                        } else if (type === "阅读理解 Quiz") {
                          return "#02baba";
                        }
                      },
                    ]}
                  />
                </Chart>
              </div>
            </div>
            </Fragment>)}
            <div className="box" id="box">
                <div className="boxs">
                <div className="box_title"><img onClick={this.handleClose} src={CloseImg} /></div>
                <div className="box_table">
                <table className="scores">
                  <thead>
                    <tr>
                    <td>
                        <span>时间 <span className="hui">Time</span></span>
                      </td>
                      <td>
                        <span>WCPM</span>
                      </td>
                    </tr>
                  </thead>
                  {voiceWCPM && voiceWCPM.length>0 && <tbody>
                    {voiceWCPM.map(({recordID,collectDate,wcpmScore})=>{ 
                        const date = new Date(collectDate);
                        return <tr key={recordID} className="fontNumber"><td>{`${date.toLocaleString('chinese', { hour12: false }).substring(9,14)}`}</td><td className={wcpmScore>=payload.wcpmTarget?'green':''}>{wcpmScore}</td>
                      </tr> 
                      })}
                  </tbody>}
                </table>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PushArticle;
