import React, { Component } from "react";

class PayList extends Component {

  handleLink = (type) => {
    switch (type) {
      case 1:
        location.href = '/pay/alipaypc';
        break;
      case 2:
        location.href = '/pay/alipaymb';
        break;
      case 3:
        location.href = '/pay/wxnative';
        break;
      case 4:
        location.href = '/api/pay/pay/jsapi';
        break;
    }
  };

  render() {
    return (
      <div>
        <button
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
          onClick={()=>this.handleLink(1)}
        >
          支付宝PC端支付
        </button>
        <button
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
          onClick={()=>this.handleLink(2)}
        >
          支付宝手机支付
        </button>
        <button
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
          onClick={()=>this.handleLink(3)}
        >
          微信二维码支付
        </button>
        <button
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
          onClick={()=>this.handleLink(4)}
        >
          微信公众号支付
        </button>
      </div>
    );
  }
}

export default PayList;
