import React, { Component } from 'react';
import { Result, Icon } from 'antd-mobile';

class Fail extends Component{

    render(){
        return <div className="result-example">
          <Result
        img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
        title='操作失败'
      />
        </div>
    }
}

export default Fail;