import React, { Component } from 'react';
import { Result } from 'antd-mobile';
import PendImg from '../../image/pend.svg';

class Pend extends Component{

    render(){
        return <div className="result-example">
          <Result
        img={<img src={PendImg} className="spe am-icon am-icon-md" alt="" />}
        title="等待处理"
      />
        </div>
    }
}

export default Pend;