import React, { Component } from "react";
import '../../css/success.css';


class Success extends Component {
    
    render(){
        return <h2>恭喜您，报名登记成功！</h2>
    }
}

export default Success;