import React, { Component } from "react";
import "../../css/statistic.css";

class Statistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 0,
      courses: undefined,
      totalUser: 0,
      totalAmount: 0,
      payAmount: 0,
      payCount: 0,
      memberCount: 0,
      name: "",
      phone: "",
      user: undefined,
      show: false,
      cdata: [],
      ctype: 0,
      cvalue: 0,
      cshow: false
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var name=prompt("请输入登录账号","");
    var pwd=prompt("请输入登录密码","");

    const data = { name: name, pwd: pwd };
    fetch("/api/active/enroll/statistic", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        return response
          .json()
          .then((data) => {
            if (data.code === 0) {
              this.setState({
                status: data.payload.status,
                courses: data.payload.courses,
                totalUser: data.payload.totalUser,
                totalAmount: data.payload.totalAmount,
                payAmount: data.payload.payAmount,
                payCount: data.payload.payCount,
                memberCount: data.payload.memberCount,
              });
              console.log(data.payload);
            } else {
              this.setState({ status: 1003 });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSearch(event) {
    fetch(
      "/api/active/enroll/search?id=0&name=" +
        this.state.name +
        "&phone=" +
        this.state.phone,
      { method: "GET" }
    )
      .then((response) => {
        return response
          .json()
          .then((data) => {
            if (data.code === 0) {
              this.setState({ user: data.payload, show: true });
              console.log(data.payload);
            } else {
              this.setState({ show: false });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleData(id){
    fetch(
      "/api/active/enroll/search?name=&phone=&id=" + id,
      { method: "GET" }
    )
      .then((response) => {
        return response
          .json()
          .then((data) => {
            if (data.code === 0) {
              this.setState({ user: data.payload, cshow: true });
              console.log(data.payload);
            } else {
              this.setState({ cshow: false });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDetail(type, value){
    const { ctype, cvalue, cdata } = this.state;
    this.setState({ ctype: type, cvalue: value });
    if(cdata && cdata.length>0){
      if(ctype == type && cvalue == value){
        this.setState({ cdata: [] });
      }else{
        fetch(
          "/api/active/enroll/courseUser?type=" + type + "&value=" + value,
          { method: "GET" }
        )
          .then((response) => {
            return response
              .json()
              .then((data) => {
                if (data.code === 0) {
                  this.setState({ cdata: data.payload });
                  console.log(data.payload);
                } else {
                  this.setState({ cdata: [] });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }else{
      fetch(
        "/api/active/enroll/courseUser?type=" + type + "&value=" + value,
        { method: "GET" }
      )
        .then((response) => {
          return response
            .json()
            .then((data) => {
              if (data.code === 0) {
                this.setState({ cdata: data.payload });
                console.log(data.payload);
              } else {
                this.setState({ cdata: [] });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    const {
      status,
      courses,
      totalUser,
      totalAmount,
      payAmount,
      payCount,
      memberCount,
      user,
      show,
      ctype,
      cvalue,
      cdata,
      cshow
    } = this.state;
    return status === 1003 ? (
      <div className="nodata">
        <h2>账号或密码错误，刷新页面重新登录！</h2>
      </div>
    ) : status === 1 ? (<div>
      <div className="static">
        <h2>报名统计信息</h2>
        <div className="rowline">
          <div className="rowleft">总报名人数：{totalUser}</div>
          <div className="rowright">总会员人数：{memberCount}</div>
        </div>
        <div className="rowline">
          <div className="rowleft">总付款人数：{payCount}</div>
          <div className="rowright">总付款金额：{payAmount}</div>
        </div>
        <div className="rowline">
          <div className="rowleft">总报名金额：{totalAmount}</div>
          <div className="rowright"></div>
        </div>
        <h2>课程统计信息</h2>
        <div className="rowline rowline-min">
          <div className="rowleft">
            <h4>课程时间：7月15日 ~ 7月17日</h4>
            <ul>
              <li>
                <a onClick={()=>this.handleDetail(1,1)}>A01： 公共演讲启蒙营（上） -{" "}
                {courses &&
                courses.c1 &&
                courses.c1.findIndex((p) => p.id === 1) >= 0
                  ? courses.c1.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==1&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(1,2)}>A03： 精读写作初阶营（上） -{" "}
                {courses &&
                courses.c1 &&
                courses.c1.findIndex((p) => p.id === 2) >= 0
                  ? courses.c1.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==1&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(1,3)}>A05： 自然拼读入门营（上） -{" "}
                {courses &&
                courses.c1 &&
                courses.c1.findIndex((p) => p.id === 3) >= 0
                  ? courses.c1.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==1&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
          <div className="rowright">
            <h4>课程时间：7月21日 ~ 7月23日</h4>
            <ul>
              <li>
              <a onClick={()=>this.handleDetail(2,1)}>B01: 公共演讲启蒙营（下） -{" "}
                {courses &&
                courses.c2 &&
                courses.c2.findIndex((p) => p.id === 1) >= 0
                  ? courses.c2.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==2&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(2,2)}>B03： 精读写作初阶营（下） -{" "}
                {courses &&
                courses.c2 &&
                courses.c2.findIndex((p) => p.id === 2) >= 0
                  ? courses.c2.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==2&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(2,3)}>B05： 自然拼读入门营（下） -{" "}
                {courses &&
                courses.c2 &&
                courses.c2.findIndex((p) => p.id === 3) >= 0
                  ? courses.c2.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==2&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="rowline rowline-min">
          <div className="rowleft">
            <h4>课程时间：7月28日 ~ 7月30日</h4>
            <ul>
              <li>
              <a onClick={()=>this.handleDetail(3,1)}>A02： 公共演讲初阶营（上） -{" "}
                {courses &&
                courses.c3 &&
                courses.c3.findIndex((p) => p.id === 1) >= 0
                  ? courses.c3.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==3&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(3,2)}>A04： 精读写作进阶营（上） -{" "}
                {courses &&
                courses.c3 &&
                courses.c3.findIndex((p) => p.id === 2) >= 0
                  ? courses.c3.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==3&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(3,3)}>A06： 自然拼读进阶营（上） -{" "}
                {courses &&
                courses.c3 &&
                courses.c3.findIndex((p) => p.id === 3) >= 0
                  ? courses.c3.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==3&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
          <div className="rowright">
            <h4>课程时间：8月4日 ~ 8月6日</h4>
            <ul>
              <li>
              <a onClick={()=>this.handleDetail(4,1)}>B02： 公共演讲初阶营（下） -{" "}
                {courses &&
                courses.c4 &&
                courses.c4.findIndex((p) => p.id === 1) >= 0
                  ? courses.c4.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==4&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(4,2)}>B04： 精读写作进阶营（下） -{" "}
                {courses &&
                courses.c4 &&
                courses.c4.findIndex((p) => p.id === 2) >= 0
                  ? courses.c4.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==4&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(4,3)}>B06： 自然拼读进阶营（下） -{" "}
                {courses &&
                courses.c4 &&
                courses.c4.findIndex((p) => p.id === 3) >= 0
                  ? courses.c4.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==4&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="rowline rowline-min">
          <div className="rowleft">
            <h4>课程时间：8月11日 ~ 8月13日</h4>
            <ul>
              <li>
              <a onClick={()=>this.handleDetail(5,1)}>A01: 公共演讲启蒙营（上） -{" "}
                {courses &&
                courses.c5 &&
                courses.c5.findIndex((p) => p.id === 1) >= 0
                  ? courses.c5.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==5&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(5,2)}>A02： 公共演讲初阶营（上） -{" "}
                {courses &&
                courses.c5 &&
                courses.c5.findIndex((p) => p.id === 2) >= 0
                  ? courses.c5.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==5&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(5,3)}>A03： 精读写作初阶营（上） -{" "}
                {courses &&
                courses.c5 &&
                courses.c5.findIndex((p) => p.id === 3) >= 0
                  ? courses.c5.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==5&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(5,4)}>A04： 精读写作进阶营（上） -{" "}
                {courses &&
                courses.c5 &&
                courses.c5.findIndex((p) => p.id === 4) >= 0
                  ? courses.c5.find((p) => p.id === 4).count
                  : 0}{" "}
                人</a>
                <div>{ctype==5&&cvalue==4&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
          <div className="rowright">
            <h4>课程时间：8月18日 ~ 8月20日</h4>
            <ul>
              <li>
              <a onClick={()=>this.handleDetail(6,1)}>B01: 公共演讲启蒙营（下） -{" "}
                {courses &&
                courses.c6 &&
                courses.c6.findIndex((p) => p.id === 1) >= 0
                  ? courses.c6.find((p) => p.id === 1).count
                  : 0}{" "}
                人</a>
                <div>{ctype==6&&cvalue==1&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(6,2)}>B02： 公共演讲初阶营（下） -{" "}
                {courses &&
                courses.c6 &&
                courses.c6.findIndex((p) => p.id === 2) >= 0
                  ? courses.c6.find((p) => p.id === 2).count
                  : 0}{" "}
                人</a>
                <div>{ctype==6&&cvalue==2&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(6,3)}>B03： 精读写作初阶营（下） -{" "}
                {courses &&
                courses.c6 &&
                courses.c6.findIndex((p) => p.id === 3) >= 0
                  ? courses.c6.find((p) => p.id === 3).count
                  : 0}{" "}
                人</a>
                <div>{ctype==6&&cvalue==3&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
              <li>
              <a onClick={()=>this.handleDetail(6,4)}>B04： 精读写作进阶营（下） -{" "}
                {courses &&
                courses.c6 &&
                courses.c6.findIndex((p) => p.id === 4) >= 0
                  ? courses.c6.find((p) => p.id === 4).count
                  : 0}{" "}
                人</a>
                <div>{ctype==6&&cvalue==4&&cdata&&cdata.length>0 ? cdata.map((data) => <a onClick={()=> this.handleData(data.studentID)} key={data.studentID}>{data.studentName}</a>) : ''}</div>
              </li>
            </ul>
          </div>
        </div>
        </div>
        {cshow && (
          <div className="bottomData">
            <h2>学生信息</h2>
            <div className="searchData">
              <div className="rowline">
                <div className="rowleft">
                  <ul>
                    <li>学生姓名：{user && user.studentName}</li>
                    <li>学生年龄：{user && user.studentAge}</li>
                    <li>手机号码：{user && user.mobilePhone}</li>
                  </ul>
                </div>
                <div className="rowright">
                  <ul>
                    <li>
                      是否会员：
                      {user ? (user.memberStatus === 1 ? "是" : "否") : ""}
                    </li>
                    <li>课程金额：{user && user.totalAmount}</li>
                    <li>
                      付款状态：
                      {user ? (user.payStatus === 2 ? "已付款" : "未付款") : ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h2>课程信息</h2>
            {user && user.course1 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月15日 ~ 7月17日</span>&nbsp;&nbsp;
                  {user.course1 === 1
                    ? "A01： 公共演讲启蒙营（上）"
                    : user.course1 === 2
                    ? "A03： 精读写作初阶营（上）"
                    : "A05： 自然拼读入门营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course2 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月21日 ~ 7月23日</span>&nbsp;&nbsp;
                  {user.course2 === 1
                    ? "B01: 公共演讲启蒙营（下）"
                    : user.course2 === 2
                    ? "B03： 精读写作初阶营（下）"
                    : "B05： 自然拼读入门营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course3 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月28日 ~ 7月30日</span>&nbsp;&nbsp;
                  {user.course3 === 1
                    ? "A02： 公共演讲初阶营（上）"
                    : user.course3 === 2
                    ? "A04： 精读写作进阶营（上）"
                    : "A06： 自然拼读进阶营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course4 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月4日 ~ 8月6日</span>&nbsp;&nbsp;
                  {user.course4 === 1
                    ? "B02： 公共演讲初阶营（下）"
                    : user.course4 === 2
                    ? "B04： 精读写作进阶营（下）"
                    : "B06： 自然拼读进阶营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course5 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月11日 ~ 8月13日</span>&nbsp;&nbsp;
                  {user.course5 === 1
                    ? "A01: 公共演讲启蒙营（上）"
                    : user.course5 === 2
                    ? "A02： 公共演讲初阶营（上）"
                    : user.course5 === 3
                    ? "A03： 精读写作初阶营（上）"
                    : "A04： 精读写作进阶营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course6 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月18日 ~ 8月20日</span>&nbsp;&nbsp;
                  {user.course6 === 1
                    ? "B01: 公共演讲启蒙营（下）"
                    : user.course6 === 2
                    ? "B02： 公共演讲初阶营（下）"
                    : user.course6 === 3
                    ? "B03： 精读写作初阶营（下）"
                    : "B04： 精读写作进阶营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            <h2>报名备注</h2>
            <div className="searchRemark">{user && user.remark}</div>
          </div>
        )}
      </div>
    ) : status === 2 ? (
      <div className="search">
        <div className="searchIn">
          <p>
            学生姓名：
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
            />
          </p>
          <p>
            手机号码：
            <input
              type="text"
              name="phone"
              id="phone"
              onChange={this.handleChange}
            />
          </p>
          <p>
            <button onClick={this.handleSearch}>查询</button>
          </p>
        </div>
        {show && (
          <div>
            <h2>学生信息</h2>
            <div className="searchData">
              <div className="rowline">
                <div className="rowleft">
                  <ul>
                    <li>学生姓名：{user && user.studentName}</li>
                    <li>学生年龄：{user && user.studentAge}</li>
                    <li>手机号码：{user && user.mobilePhone}</li>
                  </ul>
                </div>
                <div className="rowright">
                  <ul>
                    <li>
                      是否会员：
                      {user ? (user.memberStatus === 1 ? "是" : "否") : ""}
                    </li>
                    <li>课程金额：{user && user.totalAmount}</li>
                    <li>
                      付款状态：
                      {user ? (user.payStatus === 2 ? "已付款" : "未付款") : ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h2>课程信息</h2>
            {user && user.course1 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月15日 ~ 7月17日</span>&nbsp;&nbsp;
                  {user.course1 === 1
                    ? "A01： 公共演讲启蒙营（上）"
                    : user.course1 === 2
                    ? "A03： 精读写作初阶营（上）"
                    : "A05： 自然拼读入门营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course2 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月21日 ~ 7月23日</span>&nbsp;&nbsp;
                  {user.course2 === 1
                    ? "B01: 公共演讲启蒙营（下）"
                    : user.course2 === 2
                    ? "B03： 精读写作初阶营（下）"
                    : "B05： 自然拼读入门营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course3 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：7月28日 ~ 7月30日</span>&nbsp;&nbsp;
                  {user.course3 === 1
                    ? "A02： 公共演讲初阶营（上）"
                    : user.course3 === 2
                    ? "A04： 精读写作进阶营（上）"
                    : "A06： 自然拼读进阶营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course4 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月4日 ~ 8月6日</span>&nbsp;&nbsp;
                  {user.course4 === 1
                    ? "B02： 公共演讲初阶营（下）"
                    : user.course4 === 2
                    ? "B04： 精读写作进阶营（下）"
                    : "B06： 自然拼读进阶营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course5 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月11日 ~ 8月13日</span>&nbsp;&nbsp;
                  {user.course5 === 1
                    ? "A01: 公共演讲启蒙营（上）"
                    : user.course5 === 2
                    ? "A02： 公共演讲初阶营（上）"
                    : user.course5 === 3
                    ? "A03： 精读写作初阶营（上）"
                    : "A04： 精读写作进阶营（上）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            {user && user.course6 > 0 ? (
              <div className="searchCourse">
                <h4>
                  <span>课程时间：8月18日 ~ 8月20日</span>&nbsp;&nbsp;
                  {user.course6 === 1
                    ? "B01: 公共演讲启蒙营（下）"
                    : user.course6 === 2
                    ? "B02： 公共演讲初阶营（下）"
                    : user.course6 === 3
                    ? "B03： 精读写作初阶营（下）"
                    : "B04： 精读写作进阶营（下）"}
                </h4>
              </div>
            ) : (
              ""
            )}
            <h2>报名备注</h2>
            <div className="searchRemark">{user && user.remark}</div>
          </div>
        )}
      </div>
    ) : (
      <div></div>
    );
  }
}

export default Statistic;
