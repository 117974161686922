import React from "react";
import "./css/error.css";

function NotMatch() {
  return (
    <div className="error">
      <h1 className="title">404</h1>
      <h3 className="descript">页面未找到！</h3>
    </div>
  );
}

export default NotMatch;
