import React, { Component } from "react";

class WxJsapi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: undefined
    };
    this.handlePay = this.handlePay.bind(this);
  }

  handlePay = () => {
    const param = window.location.search;

    if(param && param!==""){
      const start = param.indexOf('?o=');
      const o = param.substring(start+3);
      
      fetch("/api/pay/pay/wxjsapi?openid="+ o, { method: "get" })
        .then(response => {
          return response.json();
        })
        .then(data => {

          WeixinJSBridge.invoke('getBrandWCPayRequest', {
              "appId": data.appId,
              "timeStamp":  data.timeStamp,     
              "nonceStr": data.nonceStr,      
              "package": data.package,
              "signType": data.signType,        
              "paySign": data.paySign 
          },
          function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                alert("支付成功");
              }
              else{
                alert("支付失败");
              }
          });
        });
    }
  };

  render() {
    const { payload } = this.state;

    return (
      <div>
        <button
          onClick={this.handlePay}
          style={{
            width: "350px",
            height: "70px",
            margin: "0 auto",
            fontSize: "25px",
            display: "block",
            marginTop: "50px",
            cursor: "pointer",
          }}
        >
          点我支付
        </button>
      </div>
    );
  }
}

export default WxJsapi;
