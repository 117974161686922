import React, { Component } from "react";
import { Map } from "react-amap";
import "../../css/active.css";

class ActiveSign extends Component {
  constructor(props) {
    super(props);

    this.state = {
        name: "",
        age: "",
        phone: "",
        member: 2,
        first: 0,
        second: 0,
        third: 0,
        four: 0,
        five: 0,
        six: 0,
        remark: "",
        disbutton: false,
        show: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUnBind = this.handleUnBind.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleShow(event){
    const { name, age, phone, member, first, second, third, four, five, six} = this.state;

    if(name.trim()==="" || age.trim() === "" || phone.trim() === ""){
      alert('请完善报名信息！');
      return false;
    }
    const total = first + second + third + four + five + six;
    if(total===0){
      alert('请选择报名课程！');
      return false;
    }
    var reg = /^[\u4e00-\u9fa5]{2,}$/;
    if(!reg.test(name)){
      alert('学生姓名格式错误！');
      return false;
    }
    reg = /^[0-9]{1,2}$/;
    if(!reg.test(age)){
      alert('学生年龄格式错误！');
      return false;
    }
    reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if(!reg.test(phone)){
      alert('手机号码格式错误！');
      return false;
    }

    this.setState({show: true});
  }
  handleBack(){
    this.setState({show: false});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({disbutton: true});
    const { name, age, phone, member, first, second, third, four, five, six, remark} = this.state;

    const data = {name: name, age: age, phone: phone, member:member, first:first, second:second, third:third, four:four, five:five, six:six, remark:remark };
    fetch('/api/active/enroll/user', { method: 'POST', body: JSON.stringify(data), headers: { 'content-type': 'application/json' } })
    .then(response => {
      return response.json().then(data=>{
        this.setState({disbutton: false});
        if(data.code>0){
          alert(data.msg);
        }else{
          window.location.href = '/active/success';
        }
      }).catch((error)=>{
        alert('报名失败，请刷新重试！');
        this.setState({disbutton: false});
      });
    }).catch((error)=>{
      alert('报名失败，请稍后重试！');
      this.setState({disbutton: false});
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleUnBind(event){
    const target = event.target;
    const name = target.name;
    var checks = document.getElementsByName(name);

    var old = null;
    var len = checks.length; 
    var i = 0;  
    var index = 0;
    var all = this;
    for(; i < len; i++){ 
      if(checks[i].checked){
        old = checks[i];
     }
     checks[i].onclick = function(){
       if(this === old){
        all.setState({[this.name]: 0});
        this.checked = false;
        old = null;
       }else{
        old = this;
        all.setState({[this.name]: this.value});
       }
     }
    }

  }

  render() {
    const { disbutton, show } = this.state;
    const { name, age, phone, member, first, second, third, four, five, six, remark} = this.state;

    var amount = 0;
    if(first>0){ amount = amount + 980; }
    if(second>0){ amount = amount + 980; }
    if(third>0){ amount = amount + 980; }
    if(four>0){ amount = amount + 980; }
    if(five>0){ amount = amount + 980; }
    if(six>0){ amount = amount + 980; }
    const position = { longitude: 113.935561, latitude: 22.518408 };

    return (
      <form onSubmit={this.handleSubmit}>
      <div className={show ? "formBody hideDiv" : "formBody"}>
        <div className="activelogo"></div>
        <h2>我的报名</h2>
        <div className="formContent">
            <div className="formRow">
              <label className="rowtitle">学生姓名</label>
              <input
                name="name"
                id="name"
                type="text"
                placeholder="您的姓名"
                onChange={this.handleChange}
              />
            </div>
            <div className="formRow">
              <label className="rowtitle">学生年龄</label>
              <input
                name="age"
                id="age"
                type="text"
                placeholder="您的年龄"
                onChange={this.handleChange}
              />
            </div>
            <div className="formRow">
              <label className="rowtitle">手机号码</label>
              <input
                name="phone"
                id="phone"
                type="text"
                placeholder="您的手机号"
                onChange={this.handleChange}
              />
            </div>
            <div className="formRadio">
              <label className="rowtitle">是否会员</label>
              <div className="radioBoder smallHeight">
                <p className="radioBottom">
                  <input
                    name="member"
                    id="member1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="member1">是</label>
                </p>
                <p>
                  <input
                    name="member"
                    id="member2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    defaultChecked
                  />
                  <label htmlFor="member2">否</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">7月15日 ~ 7月17日</label>
              <div className="radioBoder centerHeight">
                <p className="radioBottom">
                  <input
                    name="first"
                    id="first1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="first1">A01： 公共演讲启蒙营（上）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="first"
                    id="first2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="first2">A03： 精读写作初阶营（上）</label>
                </p>
                <p>
                  <input
                    name="first"
                    id="first3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="first3">A05： 自然拼读入门营（上）</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">7月21日 ~ 7月23日</label>
              <div className="radioBoder centerHeight">
                <p className="radioBottom">
                  <input
                    name="second"
                    id="second1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="second1">B01: 公共演讲启蒙营（下）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="second"
                    id="second2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="second2">B03： 精读写作初阶营（下）</label>
                </p>
                <p>
                  <input
                    name="second"
                    id="second3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="second3">B05： 自然拼读入门营（下）</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">7月28日 ~ 7月30日</label>
              <div className="radioBoder centerHeight">
                <p className="radioBottom">
                  <input
                    name="third"
                    id="third1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="third1">A02： 公共演讲初阶营（上）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="third"
                    id="third2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="third2">A04： 精读写作进阶营（上）</label>
                </p>
                <p>
                  <input
                    name="third"
                    id="third3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="third3">A06： 自然拼读进阶营（上）</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">8月4日 ~ 8月6日</label>
              <div className="radioBoder centerHeight">
                <p className="radioBottom">
                  <input
                    name="four"
                    id="four1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="four1">B02： 公共演讲初阶营（下）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="four"
                    id="four2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="four2">B04： 精读写作进阶营（下）</label>
                </p>
                <p>
                  <input
                    name="four"
                    id="four3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="four3">B06： 自然拼读进阶营（下）</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">8月11日 ~ 8月13日</label>
              <div className="radioBoder bigHeight">
                <p className="radioBottom">
                  <input
                    name="five"
                    id="five1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="five1">A01: 公共演讲启蒙营（上）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="five"
                    id="five2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="five2">A02： 公共演讲初阶营（上）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="five"
                    id="five3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="five3">A03： 精读写作初阶营（上）</label>
                </p>
                <p>
                  <input
                    name="five"
                    id="five4"
                    type="radio"
                    value="4"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="five4">A04： 精读写作进阶营（上）</label>
                </p>
              </div>
            </div>
            <div className="formRadio radioMager">
              <label className="rowtitle">8月18日 ~ 8月20日</label>
              <div className="radioBoder bigHeight">
                <p className="radioBottom">
                  <input
                    name="six"
                    id="six1"
                    type="radio"
                    value="1"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="six1">B01: 公共演讲启蒙营（下）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="six"
                    id="six2"
                    type="radio"
                    value="2"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="six2">B02： 公共演讲初阶营（下）</label>
                </p>
                <p className="radioBottom">
                  <input
                    name="six"
                    id="six3"
                    type="radio"
                    value="3"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="six3">B03： 精读写作初阶营（下）</label>
                </p>
                <p>
                  <input
                    name="six"
                    id="six4"
                    type="radio"
                    value="4"
                    onChange={this.handleChange}
                    onClick={this.handleUnBind}
                  />
                  <label htmlFor="six4">B04： 精读写作进阶营（下）</label>
                </p>
              </div>
            </div>
            <div className="formRemak radioMager">
              <label className="rowtitle">备注留言</label>
              <textarea
                name="remark"
                id="remark"
                placeholder="您的备注留言"
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className="formButton">
            <a className="enable" onClick={this.handleShow}>确认提交</a>
            </div>
        </div>
        <div className="formFooter">
          <h3>南山区天利名城（海岸城）三楼</h3>
          <h3>33207201/33207202</h3>
        </div>
        <div className="mapLocation">
          <Map
            amapkey="b84ce3ecaacaeb66d6b2b2c9cd25f71b"
            zoom={15}
            center={position}
            // events={{
            //   created: (ins) => {
            //     // var marker = new Map.Marker({
            //     //   position: new Map.LngLat(113.935561, 22.518408),
            //     //   title: '南山区天利名城（海岸城）三楼'
            //     // });

            //     // ins.add(marker);
            //     console.log(ins);
            //   },
            // }}
          />
        </div>
        <div className="mapgo">
          <span>大脑地图</span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.amap.com/search?query=%E5%A4%A9%E5%88%A9%E4%B8%AD%E5%A4%AE%E5%95%86%E5%8A%A1%E5%B9%BF%E5%9C%BAC%E5%BA%A7&city=440300&geoobj=113.930035%7C22.516103%7C113.940959%7C22.521372&zoom=17.5"
          >
            去这里
          </a>
        </div>
      </div>
      <div className={show ? "formInfo" : "formInfo hideDiv"}>
        <div className="studentInfo">
        <div className="rowleft">
              <ul>
                <li>学生姓名：{name}</li>
                <li>学生年龄：{age}</li>
              </ul>
            </div>
            <div className="rowright">
              <ul>
               <li>手机号码：{phone}</li>
                <li>
                  是否会员：
                  {member === 1 ? "是" : "否"}
                </li>
              </ul>
            </div>
        </div>
        <div className="remarkright">备注描述：{remark}</div>
        <h2>课程信息</h2>
        {first > 0 ? (
          <div className="courselist">
              <h5>课程时间：7月15日 ~ 7月17日</h5>
              <h6>
              {first == 1
                ? "A01： 公共演讲启蒙营（上）"
                : first == 2
                ? "A03： 精读写作初阶营（上）"
                : "A05： 自然拼读入门营（上）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        {second > 0 ? (
          <div className="courselist">
              <h5>课程时间：7月21日 ~ 7月23日</h5>
              <h6>{second == 1
                ? "B01: 公共演讲启蒙营（下）"
                : second == 2
                ? "B03： 精读写作初阶营（下）"
                : "B05： 自然拼读入门营（下）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        {third > 0 ? (
          <div className="courselist">
              <h5>课程时间：7月28日 ~ 7月30日</h5>
              <h6>{third == 1
                ? "A02： 公共演讲初阶营（上）"
                : third == 2
                ? "A04： 精读写作进阶营（上）"
                : "A06： 自然拼读进阶营（上）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        {four > 0 ? (
          <div className="courselist">
              <h5>课程时间：8月4日 ~ 8月6日</h5>
              <h6>{four == 1
                ? "B02： 公共演讲初阶营（下）"
                : four == 2
                ? "B04： 精读写作进阶营（下）"
                : "B06： 自然拼读进阶营（下）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        {five > 0 ? (
          <div className="courselist">
              <h5>课程时间：8月11日 ~ 8月13日</h5>
              <h6>{five == 1
                ? "A01: 公共演讲启蒙营（上）"
                : five == 2
                ? "A02： 公共演讲初阶营（上）"
                : five == 3
                ? "A03： 精读写作初阶营（上）"
                : "A04： 精读写作进阶营（上）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        {six > 0 ? (
          <div className="courselist">
              <h5>课程时间：8月18日 ~ 8月20日</h5>
              <h6>{six == 1
                ? "B01: 公共演讲启蒙营（下）"
                : six == 2
                ? "B02： 公共演讲初阶营（下）"
                : six == 3
                ? "B03： 精读写作初阶营（下）"
                : "B04： 精读写作进阶营（下）"} <span>980.00 元</span></h6>
          </div>
        ) : (
          ""
        )}
        <div className="lineTop">总金额：<span>{amount.toFixed(2)}</span> 元</div>
        <div className="formButton">
        <input type="submit" className={disbutton ? "unable" : "enable"} disabled={disbutton} value="确认报名" />
        <a className="backBtn" onClick={this.handleBack}>返回修改</a>
        </div>
      </div>
      </form>
    );
  }
}

export default ActiveSign;
