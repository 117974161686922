import React, { Component } from "react";
import request from "../utils/request";
import LogoImg from "../../image/share/logoblack.png";
import CloseImg from "../../image/share/close.png";
import "../../css/bind.css";

class UserUnBind extends Component {
  constructor(props){
    super(props);
    this.state = {
      oid: '',
      atoken: '',
      bindList: undefined,
      sid: 0,
      tip: '',
      msg: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {

    const param = window.location.search;

    if(param && param!==""){
      const start = param.indexOf('?o=');
      const end = param.indexOf('&t=');
      if(start>=0 && end>start){
        const o = param.substring(start+3, end);
        const t = param.substring(end+3);

        request(`/api/user/bind/wxinfo?o=${o}&t=${t}`).then(data=>{
          if(data.code === 0){
            this.setState({ oid: data.payload.id, atoken: data.payload.token, bindList: data.payload.list, sid: data.payload.list&&data.payload.list[0].id });
          }else{
            this.setState({msg: data.msg});
          }
        })
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }
  handleClick(){
    document.getElementById('box').style.display = "none";
  }


  handleSubmit(event) {
    event.preventDefault(); //阻止表单默认提交
    
    const { oid, atoken, sid, bindList } = this.state;
    if(oid=="" || atoken=="" || sid<=0){
      this.setState({tip: '抱歉，请选择解绑账号'});
      document.getElementById('box').style.display = "block";
      return;
    }

    request(`/api/user/bind/cancel`, { method: 'POST', 
    headers: {'content-type':'application/json'}, 
    body: JSON.stringify({id: oid,token: atoken, sid: sid }) })
    .then(data=>{
      if(data.code===0){
        document.getElementById("myform").reset();
        location.href = '/tip/success';
      }
      else
      {
        this.setState({tip: data.msg});
        document.getElementById('box').style.display = "block";
      }
    })
  }

  render() {
    const { oid, atoken, bindList, msg, tip } = this.state;

    return msg=="" ? <div className="bind">
      <div className="bind_logo"><img src={LogoImg} alt="星球兔" /></div>
      <form id="myform" method='post' onSubmit={this.handleSubmit}>
      <input type="hidden" id="id" name="id" value={oid} />
      <input type="hidden" id="token" name="token" value={atoken} />
      <div className="bind_select">
      <div className="bind_input border_margin">
        <span className="input_label"><span className="letspace">用户登录账号</span><span className="zhspace">Login ID</span></span>
        <span className="input_select">
            <select id="sid" name="sid" onChange={this.handleChange}>
                {bindList&&bindList.map(({id,name})=><option value={id} key={id}>{name}</option>)}
            </select>
        </span>
      </div>
     </div>         
      <div className="bind_btn">
        <input type="submit" value="解绑账号  LogOut Acc"/>
      </div>
      <div className="box_bind" id="box">
                <div className="boxs_bind">
                <div className="box_title"><img onClick={this.handleClick} src={CloseImg} /></div>
                <div className="box_table">
                  {tip}
                </div>
                </div>
            </div>
      </form>
      </div> : <div className="bind_msg">页面已失效，请重新打开</div>
  }
}

export default UserUnBind;
