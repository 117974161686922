import React, { Component } from "react";
import request from "../utils/request";
import LogoImg from "../../image/share/logoblack.png";
import CloseImg from "../../image/share/close.png";
import "../../css/bind.css";

class UserBind extends Component {
  constructor(props){
    super(props);
    this.state = {
      oid: '',
      atoken: '',
      password: undefined,
      bindList: undefined,
      account: undefined,
      phone: undefined,
      tip: '',
      msg: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {

    const param = window.location.search;

    if(param && param!==""){
      const start = param.indexOf('?o=');
      const end = param.indexOf('&t=');
      if(start>=0 && end>start){
        const o = param.substring(start+3, end);
        const t = param.substring(end+3);

        request(`/api/user/bind/wxinfo?o=${o}&t=${t}`).then(data=>{
          if(data.code === 0){
            this.setState({ nickname: data.payload.name, oid: data.payload.id, atoken: data.payload.token, bindList: data.payload.list });
          }else{
            this.setState({msg: data.msg});
          }
        })
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleClick(){
    document.getElementById('box').style.display = "none";
  }

  handleClear(){
    
  }

  handleSubmit(event) {
    event.preventDefault(); //阻止表单默认提交
    
    const { oid, atoken, account, phone, password, bindList } = this.state;
    if(oid=="" || atoken=="" || account=="" || phone=="" || password==""){
      this.setState({tip: '抱歉，请输入绑定信息'});
      document.getElementById('box').style.display = "block";
      return;
    }
    if (!(/^[1][3-9][0-9]{9}$/.test(phone))) {
      this.setState({tip: '抱歉，手机号格式错误'});
      document.getElementById('box').style.display = "block";
      return;
    }

    request(`/api/user/bind/confirm`, { method: 'POST', 
    headers: {'content-type':'application/json'}, 
    body: JSON.stringify({id: oid,token: atoken, phone: phone, account: account, password: password }) })
    .then(data=>{
      if(data.code===0){
        document.getElementById("myform").reset();
        location.href = '/tip/success';
      }
      else
      {
        this.setState({tip: data.msg});
        document.getElementById('box').style.display = "block";
      }
    })
  }

  render() {
    const { oid, atoken, account, phone, bindList, msg, tip } = this.state;

    return msg=="" ? <div className="bind">
      <div className="bind_logo"><img src={LogoImg} alt="星球兔" /></div>
      <form id="myform" method='post' onSubmit={this.handleSubmit}>
      <input type="hidden" id="id" name="id" value={oid} />
      <input type="hidden" id="token" name="token" value={atoken} />
      <div className="bind_user">
      <div className="bind_input border_margin">
        <span className="input_label"><span className="letspace">登录账号</span><span className="zhspace">Login ID</span></span>
        <span className="input_text small"><input type="text" id="account" name="account" placeholder="请输入用户登录账号" onChange={this.handleChange} /></span>
      </div>
      <div className="bind_input">
        <span className="input_label"><span className="letspace">登录密码</span><span className="zhspace">Password</span></span>
        <span className="input_text small"><input type="password" id="password" name="password" placeholder="请输入账号登录密码" onChange={this.handleChange} /></span>
      </div>
      </div>
      <div className="bind_phone">
      <div className="bind_input border_margin">
        <span className="input_label"><span className="letspace">注册手机号码</span><span className="zhspace2">Registered Mob No.</span></span>
        <span className="input_text big"><input type="text" id="phone" name="phone" placeholder="请输入注册手机号码" onChange={this.handleChange} /></span>
      </div>
      </div>

      <div className="bind_btn">
        <input type="submit" value="绑定账号  Link Acc"/>
      </div>
      <div className="box_bind" id="box">
                <div className="boxs_bind">
                <div className="box_title"><img onClick={this.handleClick} src={CloseImg} /></div>
                <div className="box_table">
                  {tip}
                </div>
                </div>
            </div>
      </form>
      </div> : <div className="bind_msg">页面已失效，请重新打开</div>
  }
}

export default UserBind;
