import React, { Component } from "react";
import html2canvas from "html2canvas";
import request from "../utils/request";
import "../../css/game.css";
import MenImg from '../../image/share/men.png';
import WomanImg from '../../image/share/woman.png';
import DownloadImg from '../../image/share/download.png';
import StarImg from '../../image/share/star.png';
import BlueUpImg from '../../image/share/blue_up.png';
import GreyUpImg from '../../image/share/grey_up.png';
import StarGreyImg from '../../image/share/star_grey.png';
import OrangeUpImg from '../../image/share/orange_up.png';
import DataLog from '../../image/data.png';
import wx from "weixin-js-sdk";

function exportImage() {
    var views = document.querySelector("#views");
    html2canvas(views, {
      scale: window.devicePixelRatio,
      useCORS: true
    }).then(function (canvas) {
      let imgUrl = canvas.toDataURL("image/png");
      let today = new Date();
      let date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate();
      downloadIamge(imgUrl, "个人学习汇报-"+ date +".png");
      //dataImg.src = canvas.toDataURL('image/png')
    });
  }
  
  function downloadIamge(imgsrc, name) {
    //下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, -15, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  }

  class PushGame extends Component {
    state = {
      payload: undefined,
      blue:0,
      green:0,
    };
  
    UNSAFE_componentWillMount(){
      const { match } = this.props;
      if (match && match.params && match.params.id) {
        request(`/api/user/push/wxconfig?sign=${match.params.id}`).then(data => {
          if(data && data.payload && data.code === 0 ){
            wx.config({
              appId: data.payload.appId, // 必填，公众号的唯一标识
              timestamp: data.payload.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.payload.nonceStr, // 必填，生成签名的随机串
              signature: data.payload.signature,// 必填，签名
              jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
            });
  
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
              //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
              wx.updateAppMessageShareData({ 
                title: data.payload.title, // 分享标题
                desc: data.payload.desc, // 分享描述
                link: data.payload.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: data.payload.imgUrl, // 分享图标
                success: function () {
                  //alert('分享成功');
                }
              });
              //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
              wx.updateTimelineShareData({ 
                title: data.payload.title, // 分享标题
                desc: data.payload.desc, // 分享描述
                link: data.payload.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: data.payload.imgUrl, // 分享图标
                success: function () {
                  //alert('分享成功');
                }
              });
          });
        }
      });
    }
  }
  
    componentDidMount() {
      document.title = '个人学习汇报';
      const { match } = this.props;
      const total = document.getElementById("progress").clientWidth;
      if (match && match.params && match.params.id) {
        request(`/api/user/push/planet?data=${match.params.id}`).then((data) => {
          if (data.code === 0) {
            const blue = data.payload ? parseInt((total/100)*data.payload.totalProgress) : 0;
            const green = data.payload ? blue - parseInt((total/100)*data.payload.todayProgress) : 0;
  
            this.setState({ payload: data.payload, blue:blue, green: green });
          }
        });
      }
    }
  
    render() {
      const { payload, blue, green } = this.state;
      return (
        <div className="share">
          <img onClick={exportImage} className="download" src={DownloadImg} alt="我的下载"/>
          <div id="views" className="share_all">
          <div className="share_top">
            <div className="share_top_div left">
            <img src={payload&&(payload.sex===2 ? WomanImg : payload.sex===1 ? MenImg : "")} alt="我的头像" />
            </div>
            <div className="share_top_div left">
              <p className="fontSize_max fontSize_weight marginTop fontColor_white">{payload&&payload.studentName}&nbsp;{payload&&payload.loginName}</p>
              <p className="fontSize_small fontColor_grey">姓名 Name</p>
              <p className="fontSize_max fontColor_white"><span className="fontSize_weight">{payload&&payload.planetAlias}</span>&nbsp;<span className="fontNumber">{payload&&payload.planetName}</span></p>
              <p className="fontSize_small fontColor_grey">级别 Grade</p>
              <p className="fontSize_max fontNumber fontColor_white">{payload&&payload.dateNow}</p>
              <p className="fontSize_small fontColor_grey">日期 Date</p>
              <p className="fontSize_max fontNumber fontColor_white">{payload&&payload.useDay}</p>
              <p className="fontSize_small fontColor_grey">训练天数 Days</p>
              <a className="share_link" href={`/push/ability/${this.props.match.params.id}`}><img src={DataLog} title="我的能力" alt="我的能力" /></a>
            </div>
          </div>
          <div className="share_content">
            {payload&&payload.games&&payload.games.map((game,index)=>{
              const flag = index===0 ? false : index===1 ? true : (0 !== (index&(index-1))&& index > 1);
              let circle = '';
              if(flag){
                if(game.totalProgress<50){
                  circle = `linear-gradient(90deg, #e6d8d1 50%, rgba(230, 216, 209, 0) 50%, rgba(230, 216, 209, 0)), linear-gradient(${90+3.6*game.totalProgress}deg, #ffa06d 50%, #e6d8d1 50%, #e6d8d1)`;
                }else if(game.totalProgress===50){
                  circle = `linear-gradient(-90deg, #ffa06d 50%, rgba(230, 216, 209, 0) 50%, rgba(230, 216, 209, 0)), linear-gradient(270deg, #e6d8d1 50%, #e6d8d1 50%, #e6d8d1)`;
                }else if(game.totalProgress<75){
                  circle = `linear-gradient(${-90+3.6*(game.totalProgress-50)}deg, #ffa06d 50%, rgba(230, 216, 209, 0) 50%, rgba(230, 216, 209, 0)), linear-gradient(270deg, #ffa06d 50%, #e6d8d1 50%, #e6d8d1)`;
                }else if(game.totalProgress===75){
                  circle = `linear-gradient(0deg, #ffa06d 50%, rgba(230, 216, 209, 0) 50%, rgba(230, 216, 209, 0)), linear-gradient(270deg, #ffa06d 50%, #e6d8d1 50%, #e6d8d1)`;
                }else if(game.totalProgress<=100){
                  circle = `linear-gradient(${3.6*(game.totalProgress-75)}deg, #ffa06d 50%, rgba(230, 216, 209, 0) 50%, rgba(230, 216, 209, 0)), linear-gradient(270deg, #ffa06d 50%, #e6d8d1 50%, #e6d8d1)`;
                }
              }else{
                if(game.totalProgress<50){
                  circle = `linear-gradient(90deg, #cad2e5 50%, rgba(202, 210, 229, 0) 50%, rgba(202, 210, 229, 0)), linear-gradient(${90+3.6*game.totalProgress}deg, #72a0fe 50%, #cad2e5 50%, #cad2e5)`;
                }else if(game.totalProgress===50){
                  circle = `linear-gradient(-90deg, #72a0fe 50%, rgba(202, 210, 229, 0) 50%, rgba(202, 210, 229, 0)), linear-gradient(270deg, #cad2e5 50%, #cad2e5 50%, #cad2e5)`;
                }else if(game.totalProgress<75){
                  circle = `linear-gradient(${-90+3.6*(game.totalProgress-50)}deg, #72a0fe 50%, rgba(202, 210, 229, 0) 50%, rgba(202, 210, 229, 0)), linear-gradient(270deg, #72a0fe 50%, #cad2e5 50%, #cad2e5)`;
                }else if(game.totalProgress===75){
                  circle = `linear-gradient(0deg, #72a0fe 50%, rgba(202, 210, 229, 0) 50%, rgba(202, 210, 229, 0)), linear-gradient(270deg, #72a0fe 50%, #cad2e5 50%, #cad2e5)`;
                }else if(game.totalProgress<=100){
                  circle = `linear-gradient(${3.6*(game.totalProgress-75)}deg, #72a0fe 50%, rgba(202, 210, 229, 0) 50%, rgba(202, 210, 229, 0)), linear-gradient(270deg, #72a0fe 50%, #cad2e5 50%, #cad2e5)`;
                }
              }
  
              return <div key={game.gameID} className={`share_game ${flag ? 'orangeBg' : 'blueBg'}`}>
                <div className="game_title">
                  <img src={game.gameIco} alt="我的游戏"/>
                  <span>{game.gameName}</span>
                </div>
                <div className="game_today">
                  <div className="game_today_div left">
                    <h2>今日进度 <span className="smallTitle">Today Progress</span></h2>
                    <h1 className={`fontNumber ${flag ? 'fontColor_orage' : 'fontColor_blue'}`}>+{game.dayProgress}%</h1>
                  </div>
                  <div className="game_today_div left">
                    <h2>今日得分 <span className="smallTitle">Today Score</span></h2>
                    <h1 className={`fontNumber ${flag ? 'fontColor_orage' : 'fontColor_blue'}`}>{game.score}</h1>
                  </div>
                </div>
                <div className="game_progress">
                  <div className="game_progress_div left">
                      <h2>总进度 <span className="smallTitle">Total Progress</span></h2>
                      <div className="totalProgress">
                        <div className={`progress-radial`} style={{backgroundImage: circle}}>
                            <div className={`overlay ${flag ? 'fontColor_greyBlack' : 'fontColor_green'} fontNumber`}>{game.totalProgress}%</div>
                        </div>
                      </div>
                    </div>
                    <div className="game_progress_div left">
                      <h2>今日超分 <span className="smallTitle">Today Update</span></h2>
                      <div className="todayUpdate">
                        <img src={game.breakStatus ? StarImg : StarGreyImg} alt="我的超分" />
                      </div>
                      <h2>今日升级 <span className="smallTitle">Today Upgrade</span></h2>
                      <div className="todayUpgrade">
                        {game.upgrade > 0 ? <div className={`upgrade_${game.upgrade}`}>
                          {
                            [...Array(game.upgrade)].map((e,i)=><img key={i} src={flag ? OrangeUpImg : BlueUpImg} alt="我的升级" />)
                          }
                        </div> : <div className={`upgrade_1`}><img src={GreyUpImg} alt="我的升级" /></div>}
                      </div>
                    </div>
                </div>
                <div className="game_time">
                  <div className="game_time_div left">
                  <h2>训练时间 <span className="smallTitle">Time</span></h2>
                  <div className={`fontNumber border ${flag ? 'border_orange' : 'border_blue'}`}>{game.times}</div>
                  </div>
                  <div className="game_time_div left">
                  <h2>今日答题数 <span className="smallTitle">Trails/Minutes</span></h2>
                  <div className={`fontNumber border ${flag ? 'border_orange' : 'border_blue'}`}>{game.answer}</div>
                  </div>
                </div>
              </div>
            })}
  
            <div className="share_propgress">
              <div className="planet_ico left"><img src={payload&&payload.planetIco} alt="我的星球" /></div>
              <div className="planet_progress left">
                <h2>级别总进度 <span className="progressTitle">Total Progress</span><span className="fontNumber progress_tip">+{payload&&payload.todayProgress}%</span></h2>
                <div id="progress" className="progress_border">
                  <div id="blue" className="progress_border_blue" style={{width: blue}}></div>
                  <div id="green" className="progress_border_green" style={{width: green}}></div>
                </div>
              </div>
              <div className="fontNumber progress_total left">{payload&&payload.totalProgress}%</div>
            </div>
          </div>
        </div>
        </div>
      );
    }
  }

export default PushGame;