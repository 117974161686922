import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import UserBase from './js/user/index';
import UserBind from './js/user/bind';
import UserUnBind from './js/user/unbind';
import Fail from './js/result/fail';
import Warn from './js/result/warn';
import Pend from './js/result/pend';
import ActivePage from './js/active/index';
import JsApiPay from './js/active/pay';
import Statistic from './js/active/statistic';
import AcSuccess from './js/active/success';
import PushGame from './js/push/game';
import PushArticle from './js/push/article';
import PushAbility from './js/push/ability';
import ServerBad from './500';
import NoMtach from './404';
import Success from './js/tip/success';
import PayList from './js/pay/paylist';
import AlipayPC from './js/pay/alipaypc';
import AlipayMB from './js/pay/alipaymb';
import WxJsapi from './js/pay/wxjsapi';
import WxNative from './js/pay/wxnative';

function App() {
  return <Router>
    <Switch>
      <Route exact path="/user/index" render={ props => { document.title = "首页"; return <UserBase {...props} /> }}/>
      <Route exact path="/user/bind" render={ props => { document.title = "微信绑定"; return <UserBind {...props} /> }}/>
      <Route exact path="/user/unbind" render={ props => { document.title = "微信解绑"; return <UserUnBind {...props} /> }}/>
      <Route exact path="/push/game/:id" render={ props => { document.title = "我的游戏"; return <PushGame {...props} />} } />
      <Route exact path="/push/ability/:id" render={ props => { document.title = "我的能力"; return <PushAbility {...props} />} } />
      <Route exact path="/push/article/:id" render={ props => { document.title = "我的口语"; return <PushArticle {...props} />} } />
      <Route exact path="/result/success" render={ props => <Success {...props} />}/>
      <Route exact path="/result/fail" render={ props => <Fail {...props} />}/>
      <Route exact path="/result/warn" render={ props => <Warn {...props} />}/>
      <Route exact path="/result/pend" render={ props => <Pend {...props} />}/>
      <Route exact path="/500" render={ props => <ServerBad {...props} />}/>
      <Route exact path="/404" render={ props => <NoMtach {...props} />}/>
      <Route exact path="/tip/success" render={ props => { document.title = "操作成功"; return <Success {...props} />} }/>
      <Route exact path="/active/index" render={ props =>{ document.title = "英语夏令营"; return <ActivePage {...props} />} }/>
      <Route exact path="/active/pay/:sid/:id" render={ props => { document.title = "微信支付"; return <JsApiPay {...props} />} }/>
      <Route exact path="/active/statistic" render={ props => { document.title = "统计"; return <Statistic {...props} />} }/>
      <Route exact path="/active/success" render={ props =>{ document.title = "报名成功"; return <AcSuccess {...props} />} }/>
      <Route exact path="/pay/paylist" render={ props =>{ document.title = "支付列表"; return <PayList {...props} />} }/>
      <Route exact path="/pay/alipaypc" render={ props =>{ document.title = "支付宝PC端支付"; return <AlipayPC {...props} />} }/>
      <Route exact path="/pay/alipaymb" render={ props =>{ document.title = "支付宝手机支付"; return <AlipayMB {...props} />} }/>
      <Route exact path="/pay/wxjsapi" render={ props =>{ document.title = "微信公众号支付"; return <WxJsapi {...props} />} }/>
      <Route exact path="/pay/wxnative" render={ props =>{ document.title = "微信二维码支付"; return <WxNative {...props} />} }/>
      
      {/* <Route path="*" render={ props => <NoMtach {...props} /> } /> */}
    </Switch>
  </Router>
}

export default App;
