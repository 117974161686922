import React, { Component } from "react";
import html2canvas from "html2canvas";
import request from "../utils/request";
import "../../css/ability.css";
import MenImg from "../../image/share/men.png";
import WomanImg from "../../image/share/woman.png";
import DownloadImg from "../../image/share/download.png";
import DataLog from "../../image/data.png";
import { Chart, Axis, Geom, Tooltip, Coord, Legend } from "bizgoblin";
import { color } from "ansi-styles";

function exportImage() {
  var views = document.querySelector("#views");
  html2canvas(views, {
    scale: window.devicePixelRatio,
    useCORS: true,
  }).then(function (canvas) {
    let imgUrl = canvas.toDataURL("image/png");
    let today = new Date();
    let date =
      today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate();
    downloadIamge(imgUrl, "个人学习汇报-" + date + ".png");
    //dataImg.src = canvas.toDataURL('image/png')
  });
}

function downloadIamge(imgsrc, name) {
  //下载图片地址和图片名
  let image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let context = canvas.getContext("2d");
    context.drawImage(image, -15, 0, image.width, image.height);
    let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    let a = document.createElement("a"); // 生成一个a元素
    let event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}

class PushAbility extends Component {
  state = {
    payload: undefined,
  };

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      request(`/api/user/push/ability?data=${match.params.id}`).then((data) => {
        if (data.code === 0) {
          this.setState({ payload: data.payload });
        }
      });
    }
  }

  render() {
    const { payload } = this.state;

    const defs = [{
        dataKey: 'name',
      }, {
        dataKey: 'value',
        formatter(value) {
            return `${value}%`;
          }
      }];

    return (
      <div className="share">
        <img
          onClick={exportImage}
          className="download"
          src={DownloadImg}
          alt="我的下载"
        />
        <div id="views" className="share_all">
          <div className="share_top">
            <div className="share_top_div left">
              <img
                src={
                  payload &&
                  (payload.sex === 2
                    ? WomanImg
                    : payload.sex === 1
                    ? MenImg
                    : "")
                }
                alt="我的头像"
              />
            </div>
            <div className="share_top_div left">
              <p className="fontSize_max fontSize_weight marginTop fontColor_white">
                {payload && payload.studentName}&nbsp;
                {payload && payload.loginName}
              </p>
              <p className="fontSize_small fontColor_grey">姓名 Name</p>
              <p className="fontSize_max fontColor_white">
                <span className="fontSize_weight">
                  {payload && payload.planetAlias}
                </span>
                &nbsp;
                <span className="fontNumber">
                  {payload && payload.planetName}
                </span>
              </p>
              <p className="fontSize_small fontColor_grey">级别 Grade</p>
              <p className="fontSize_max fontNumber fontColor_white">
                {payload && payload.dateNow}
              </p>
              <p className="fontSize_small fontColor_grey">日期 Date</p>
              <p className="fontSize_max fontNumber fontColor_white">
                {payload && payload.useDay}
              </p>
              <p className="fontSize_small fontColor_grey">训练天数 Days</p>
              <a
                className="share_link"
                href={`/push/game/${this.props.match.params.id}`}
              >
                <img src={DataLog} title="我的能力" alt="我的能力" />
              </a>
            </div>
          </div>
          <div className="share_content">
            {payload && payload.parent && (
              <div id="parent">
                <h3>整体训练</h3>
                <Chart width="100%" height="400" defs={defs} data={payload.parent} animate={{ type: 'scaley' }} padding={[ 80, 10, 10, 10]}>
                  <Coord type="polar" innerRadius={0} />
                  <Axis
                    dataKey="name"
                    show={false}
                  />
                  <Axis
                    dataKey="value"
                  />
                  <Tooltip offsetY={-10} />
                  <Geom
                    geom="interval"
                    position="name*value"
                    color={[
                      "name*value*color",
                      (na, value, color) => {
                        return color;
                      },
                    ]}
                    style={{ lineWidth: 1, stroke: "#FFF" }}
                  />
                </Chart>
              </div>
            )}
            {payload && payload.sub && (
              <div id="sub">
                  <h3>训练项目</h3>
                <Chart width="100%" height="400" defs={defs} data={payload.sub} animate={{ type: 'scaley' }} padding={[ 80, 10, 10, 10]}>
                  <Coord type="polar" innerRadius={0} />
                  <Axis
                    dataKey="name"
                    show={false}
                  />
                  <Axis
                    dataKey="value"
                  />
                  <Tooltip offsetY={-10} />
                  <Geom
                    geom="interval"
                    position="name*value"
                    color={[
                      "name*value*color",
                      (na, value, color) => {
                        return color;
                      },
                    ]}
                    style={{ lineWidth: 1, stroke: "#FFF" }}
                  />
                </Chart>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PushAbility;
