import React, { Component } from "react";
import SuccessImg from '../../image/success.png';
import '../../css/success.css';


class Success extends Component {
    
    render(){
        return <div className="tip">
            <div className="tip_img"><img alt="成功" src={SuccessImg}/></div>
            <div className="tip_msg">恭喜您，操作成功！</div>
        </div>
    }
}

export default Success;