
export default function request(url, options){
    return fetch(url, options)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response.json().then(data=>{
        return data
      }).catch((error)=>{
        return {code: 6009, msg: 'error'}
      });
    })
}