import React, { Component } from "react";
import QRCode from "qrcode.react";

class WxNative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  componentDidMount(){
    fetch("/api/pay/pay/wxnative", { method: "get" })
      .then(response => {
        return response.text();
      })
      .then(data => {
        console.log(data);
        this.setState({ url: data });
      });
  }

  render() {
    const { url } = this.state;

    return (
      <div>
        <div style={{
            marginTop: "50px",
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center'
          }}>打开微信扫一扫</div>
        <div style={{
            width: "350px",
            height: "350px",
            margin: "0 auto",
            marginTop: "50px"
          }}>{url.length>0 && <QRCode id="qrCode" includeMargin={true} size={350} level="H" value={url}/>}</div>
      </div>
    );
  }
}

export default WxNative;
