import React, { Component } from "react";
import '../../css/pay.css';

class JsApiPay extends Component {
  constructor(props){
    super(props);

    this.state = {
      amount: 0,
      show: false
    }

    this.handlePay = this.handlePay.bind(this);
  }

  componentDidMount(){
    const { match } = this.props;

    const id = match.params.id;
    const sid = match.params.sid;

    const data = { id: id, sid: sid };
    console.log(data);
    fetch("/api/active/enroll/jsamout?id="+id+"&sid="+sid, { method: "GET" })
      .then((response) => {
        return response
          .json()
          .then((data) => {
            if (data.code === 0) {
              this.setState({amount: data.payload.amount});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePay(){
    const { match } = this.props;

    const id = match.params.id;
    const sid = match.params.sid;

    const data = { id: id, sid: sid };
    console.log(data);
    fetch("/api/active/enroll/jsapi", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        return response
          .json()
          .then((data) => {
            if (data.code === 0) {
              WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": data.payload.appId,
                "timeStamp":  data.payload.timeStamp,     
                "nonceStr": data.payload.nonceStr,      
                "package": data.payload.package,
                "signType": data.payload.signType,        
                "paySign": data.payload.paySign 
            },
            function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  window.location.href = '/active/paysuccess';
                }else{
                  alert("支付失败");
                }
            });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { amount, show } = this.state;
    return !show ? <div className="payBody">
    <h2>课程报名支付</h2>
    <h1><span>{amount}</span> 元</h1>
    <div className="payButton"><button onClick={this.handlePay}>确认支付</button></div>
  </div> : <h2 className="paySuccess">恭喜您，支付成功！</h2>
  }
}

export default JsApiPay;
