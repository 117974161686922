import React, { Component } from 'react';
import { Result } from 'antd-mobile';
import warnImg from '../../image/warn.svg';

class Warn extends Component{

    render(){
        return <div className="result-example">
          <Result
        img={<img src={warnImg} className="spe am-icon am-icon-md" alt="" />}
        title="无法操作"
      />
        </div>
    }
}

export default Warn;